// $primary: #fc6ac2;
// $secondary: white;
// $accent: #fa32aa;
// $background-color: #fceef6;
// $background-accent: #ffdff1;
// $button-bg-color: #f86cc0;
// $button-bg-hover-color: #fc4ab5;
// $text-color: rgba(87, 87, 87, 0.9);
$primary: #2a2a2a;
$primary-transparent: #2a2a2a69;
$secondary: white;
$accent: #d20000;
$background-color: #f0f0f0;
$background-accent: #ff0000;
$secondary-bg-color: #ff0000;
$button-bg-color: #ff0000;
$button-bg-hover-color: #d20000;
$text-color: rgba(51, 51, 51, 0.9);
