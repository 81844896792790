@import './assets/sass/styles.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'DK Lemon Yellow Sun', 'BRIGHTSIGHT02', 'Amatic SC',
    'Indie Flower', 'Ubuntu', Arial -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

.button,
a,
form,
input {
  font-family: 'DK Lemon Yellow Sun', 'BRIGHTSIGHT02', 'Amatic SC',
    'Indie Flower', 'Ubuntu', Arial -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 769px) {
  body {
    overflow-x: hidden;
  }
}
